import React from 'react';
import '../../../assets/whypc/css/index.css';
import beian from "../../../assets/whypc/image/beian.png";
import {Layout} from "antd";
const { Footer } = Layout;
class Footers extends React.Component {
    componentDidMount(){

    }
    feedBack = ()=>{
        console.log(11111)
    }
    render() {

        return (<Footers className="bootom">
                <div className="feet">
                    <p>联系电话：4008809382 |<a href="/#">服务条款</a> | <a href="/#" onClick={this.feedBack()}> 意见反馈</a></p>
                    <p><a href="https://beian.miit.gov.cn/#/Integrated/index"> 增值电信业务经营许可证：豫B2-20130011-4 | Copyright &copy; 2014-2016 www.hnbxwhy.com 文化云 版权所有，未经授权禁止复制或镜像
                        <img src={beian} alt="百姓文化云备案"/></a>
                    </p>
                </div>

            </Footers>
        )
    }
}
export default Footers;
