import React from 'react';
//import React, { Component,useMemo, useState, useEffect,useRef,useCallback } from 'react';
import "video.js/dist/video-js.css";
import "videojs-flash";
import videojs from "video.js";
//import ReactPlayer from 'react-player';
import Api from "../../model/Api";
import Axios from "axios";
//import {Redirect,withRouter}from 'react-router-dom';
//import list1 from "../../../assets/whypc/image/list1.png";
import list2 from "../../../assets/whypc/image/list2.png";
// import list3 from "../../../assets/whypc/image/list3.png";
// import list4 from "../../../assets/whypc/image/list4.png";
// import list5 from "../../../assets/whypc/image/list5.png";
import list6 from "../../../assets/whypc/image/list6.png";

import '../../../assets/whypc/css/list.css';
import "../../../../node_modules/video-react/dist/video-react.css";

//import { Layout, Menu, Breadcrumb,Carousel,input,message, } from 'antd';
//import { Layout } from 'antd';
// import  zhibo from "../../../assets/whypc/image/zhibo.png";
// import  my from "../../../assets/whypc/image/my.png";
// import videopg from "../../../assets/whypc/image/playering3.png";
// import videopg2 from "../../../assets/whypc/image/playering2.png";
// import videopg3 from "../../../assets/whypc/image/playering3.png";
// import videopg4 from "../../../assets/whypc/image/playering4.png";
// import videopg5 from "../../../assets/whypc/image/playering5.png";
import background from "../../../assets/whypc/image/background.png";
//import { Player, ControlBar, ReplayControl,ForwardControl, CurrentTimeDisplay,TimeDivider, PlaybackRateMenuButton, VolumeMenuButton } from 'video-react';

//const { Header, Content,Footer } = Layout;

class List extends React.Component{


    constructor(props) {

        super(props);
       // this.myRef = React.createRef();
        this.state = {
            //cid:this.props.id,
            typeid:this.getQueryString(this.props.location.search,'typeid'),
            cid:this.getQueryString(this.props.location.search,'id'),
            current:1,
            zhuantiid:this.getQueryString(this.props.location.search,'serialNumber'),
            windowwidth:'1990px',
            windowheight:'800px',
            setShowNextButton:0,
            list1:'',
            vcount:5,
            abc:'0',
            videosrc:  '',
            li:0,
            vsrc:[
            ],
            appmenusimg:[
                {
                    'title':' ',
                    'src': '',
                    'starttime':' ',
                    'endtime':' ',
                    'color':'#000',
                    'width':'90%',
                    'height':'70%',
                    'imagewidth':'97%'
                },
            ],
            activityname:'',
            coverimg:'',
            activityStartTime:"",
            activityEndTime:'',
            activityId:'',
            redon:0,
            ontitle:' ',
            onimg:'',
            onimgshow:'none',
            onvideo:'block',
            onstarttime:'',
            list:'',
            bl:'?x-oss-process=style/wbpdt',
            bls:'?x-oss-process=style/wbplbl',
            isgetData:false
        };
        this.player=null;
        this.onScrollCapture = this.onScrollCapture.bind(this);

    }


    componentDidMount(){
        // if(localStorage.getItem("newplayer")!=1){
        //     localStorage.setItem("newplayer",1);
        //     window.location.reload();
        //     return;
        // }

        window.scrollTo(0, 1000);

        this.gitbanner(this.state.typeid,this.state.cid)
        this.times=setTimeout(()=>{
            if(this.getlist(this.state.zhuantiid,this.state.typeid)){
                if(localStorage.getItem("newplayer")!=1){//eslint-disable-line
                    localStorage.setItem("newplayer",1);
                    window.location.reload();
                }
                if(this.state.vsrc[0].indexOf(".mp4")==-1) {//eslint-disable-line
                    this.timer=setInterval( ()=>{
                        let nowtime=(new Date()).valueOf();
                        let startimes=(new Date(this.state.appmenusimg[0]['starttime'])).valueOf();
                        let endtimes=(new Date(this.state.appmenusimg[0]['endtime'])).valueOf();
                        if (nowtime >= startimes && nowtime <= endtimes) {
                            this.handleChange('onvideo', 'block');
                            this.plays(this.state.vsrc[0])
                            this.handleChange('onimgshow', 'none');
                            this.handleChange('videosrc', this.state.vsrc[0]);
                            clearInterval(this.timer);
                        } else {
                            this.handleChange('videosrc', '');
                            this.handleChange('onimg', this.state.appmenusimg[0]['src']);
                            this.handleChange('onvideo', 'none');
                            this.handleChange('onimgshow', 'block');
                        }

                    }, 2000);
                }else{
                    this.handleChange('onvideo','block');
                    this.handleChange('onimgshow','none');
                    this.plays(this.state.vsrc[0])
                }
                localStorage.setItem("seletKey",2);
                this.handleChange('windowwidth',document.body.clientWidth*0.92);
                this.handleChange('windowheight',document.body.clientHeight*0.96);

            }
        },500)
        localStorage.setItem("newplayer",0);

    }
    componentWillUnmount() {
        clearInterval(this.timer);
        clearInterval(this.timers);

        this.setState = (state,callback)=>{

        };

    }
    getQueryString= (url,name) =>{
        var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
        var r = url.substr(1).match(reg);
        if (r != null) {
            return unescape(r[2]);
        }
        return null;
    }
    //获取banner
    gitbanner=(typeid,cid)=>{

        var params = new URLSearchParams();
        params.append('id',cid);
        Axios.post(Api.getCloudBanner,params).then((res)=>{
            let bannerimg=''

            if(cid==10||typeid==1){//eslint-disable-line
                bannerimg=res.data.data.coverImageUrl
            }else{
                bannerimg= res.data.data.topImageUrl


            }

            //this.state.list1=bannerimg;
            this.setState({list1:bannerimg,isgetData:true})
        })

    }

    getlist=(zhuantiid,typeid,page, pageSize, values,open)=>{

        if(zhuantiid!=''){//eslint-disable-line
            Axios.get(Api.getFestival+'?festivalNo='+zhuantiid,)
                .then((res) => {
                    if(res.data.data==null){
                        return
                    }

                    let newListArray = [];
                    let videoArray=[];
                    if(res.data.data.moduleVoList.length!=0){//eslint-disable-line
                        for(var i=0;i<res.data.data.moduleVoList.length;i++){
                            if(res.data.data.moduleVoList[i].moduleResource.lenght!=0){//eslint-disable-line
                                var datalist=res.data.data.moduleVoList[i].moduleResource
                                // console.log(datalist);
                                //eslint-disable-next-line no-loop-func
                                datalist.forEach((list,index) => {
                                    if(list['resourceRelType']!=4){//eslint-disable-line
                                        if(list['resourceRelType']!=2){//eslint-disable-line

                                            if(list['resourceRelType']==6||list['resourceRelType']==7){//eslint-disable-line
                                                if(list['resourcesVo']['resourceUrl'].indexOf(".mp4")!="-1"){//eslint-disable-line
                                                    newListArray.push({
                                                        'title':list['resourceTitle'],
                                                        'src': list['coverImgUrl'],
                                                        'starttime':list['activityStartTime']?list['activityStartTime']:'',
                                                        'endtime':list['activityEndTime']?list['activityEndTime']:'',
                                                        'color':'#000',
                                                        'width':'90%',
                                                        'height':'70%',
                                                        'imagewidth':'97%'
                                                    });
                                                    videoArray.push(list['resourcesVo']['resourceUrl'])
                                                }


                                            }else{
                                                //console.log(datalist[index])
                                                if(datalist[index]['resourcesVo']['resourceRel'].indexOf(".mp4")!="-1") {//eslint-disable-line
                                                    newListArray.push({
                                                        'title':datalist[index]['resourceTitle'],
                                                        'src': datalist[index]['coverImgUrl'],
                                                        'starttime':datalist[index]['activityStartTime']?datalist[index]['activityStartTime']:'',
                                                        'endtime':datalist[index]['activityEndTime']?datalist[index]['activityEndTime']:'',
                                                        'color':'#000',
                                                        'width':'90%',
                                                        'height':'70%',
                                                        'imagewidth':'97%'
                                                    });
                                                    videoArray.push(datalist[index]['resourceRel'])
                                                }
                                            }
                                            this.setState({
                                                appmenusimg: newListArray,
                                                vsrc: videoArray,
                                                ontitle:this.state.appmenusimg[0]['title'],
                                                onimg:this.state.appmenusimg[0]['src'],
                                                onstarttime:this.state.appmenusimg[0]['starttime']
                                            })
                                        }else{

                                            Axios.get(Api.getLiveInfo+'?activityId='+datalist[index]['activityVo']['id'],).then((infores)=>{
                                                //console.log(datalist[index])
                                                var playurl=infores.data.data.playUrl;
                                                //  console.log(datalist[index])
                                                // console.log(playurl)
                                                if(playurl!=''&&datalist[index]!= undefined){//eslint-disable-line
                                                    newListArray.push({
                                                        'title':datalist[index]['resourceTitle'],
                                                        'src': datalist[index]['coverImgUrl'],
                                                        'starttime':datalist[index]['activityStartTime']?datalist[index]['activityStartTime']:infores.data.data['liveStart'],
                                                        'endtime':datalist[index]['activityEndTime']?datalist[index]['activityEndTime']:'',
                                                        'color':'#000',
                                                        'width':'90%',
                                                        'height':'70%',
                                                        'imagewidth':'97%'
                                                    });
                                                    newListArray.forEach((item,index)=>{
                                                        if(index==0){//eslint-disable-line
                                                            newListArray[index]['color']="red";
                                                        }
                                                    })
                                                    videoArray.push(playurl)
                                                    this.setState({
                                                        appmenusimg: newListArray,
                                                        vsrc: videoArray,
                                                        ontitle:this.state.appmenusimg[0]['title'],
                                                        onimg:this.state.appmenusimg[0]['src'],
                                                        onstarttime:this.state.appmenusimg[0]['starttime']
                                                    })
                                                }
                                            })
                                        }
                                    }


                                })
                            }
                        }
                    }
                    let newListArray2 = [];
                    let videoArray2=[];
                    let activityId="";
                    let activityName="";
                    let  coverImgUrl=''

                    if(res.data.data.relActivity.length!=0){//eslint-disable-line
                        for(var a=0;a<res.data.data.relActivity.length;a++){
                            var datalist2=res.data.data.relActivity[a].actList
                            //eslint-disable-next-line no-loop-func
                            datalist2.forEach((list,index) => {
                                activityId=datalist2[index]['activityId'];
                                activityName=datalist2[index]['activityName'];
                                coverImgUrl=datalist2[index]['coverImgUrl']
                                Axios.get(Api.getLiveInfo+'?activityId='+activityId).then((infores)=>{
                                    if(infores.data.data!=null){//eslint-disable-line
                                        var playurl=infores.data.data.playUrl
                                        if(playurl!=''){//eslint-disable-line
                                            newListArray2.push({
                                                'title':activityName,
                                                'src':  coverImgUrl,
                                                'starttime':infores.data.data.liveStart?infores.data.data.liveStart:'',
                                                'endtime':'',
                                                'color':'#000',
                                                'width':'90%',
                                                'height':'70%',
                                                'imagewidth':'97%'
                                            });
                                            newListArray2.forEach((item,index)=>{
                                                if(index==0){//eslint-disable-line
                                                    newListArray2[index]['color']="red";
                                                }
                                            })
                                            videoArray2.push(playurl)
                                            this.setState({
                                                appmenusimg: newListArray2,
                                                vsrc: videoArray2,
                                                ontitle:this.state.appmenusimg[0]['title'],
                                                onimg:this.state.appmenusimg[0]['src'],
                                                onstarttime:this.state.appmenusimg[0]['starttime']
                                            })
                                        }
                                    }


                                })
                            })

                        }
                    }

                })
                .catch((error) => {
                    console.log(error);
                });
        }else{
            Axios.get(Api.getLives+'?current=1&resourceLevel=SABCD&size=10').then((infores)=>{
                let vlist=infores.data.data.list;
                let newListArray=[];
                let videoArray=[];

                vlist.forEach((list,index)=>{
                    console.log(vlist[index])
                    newListArray.push({
                        'title':vlist[index]['activity_name'],
                        'src':  vlist[index]['cover_img_url'],
                        'starttime':this.formatDate(vlist[index]['video_live_start_time'],'yyyy-m-h H:i:s'),
                        'endtime':this.formatDate(vlist[index]['activity_end_time'],'yyyy-m-h H:i:s'),
                        'color':'#000',
                        'width':'90%',
                        'height':'70%',
                        'imagewidth':'97%'
                    })
                    newListArray.forEach((item,index)=>{
                        if(index==0){//eslint-disable-line
                            newListArray[index]['color']="red";
                        }
                    })
                    if(vlist[index]['use_play_url']==0){//eslint-disable-line
                        videoArray.push(vlist[index]['original_play_url'])
                    }else{
                            videoArray.push(vlist[index]['play_url'])
                    }


                    this.setState({
                        appmenusimg: newListArray,
                        vsrc: videoArray,
                        ontitle:this.state.appmenusimg[0]['title'],
                        onimg:this.state.appmenusimg[0]['src'],
                        onstarttime:this.state.appmenusimg[0]['starttime']
                    })

                })
            })
        }


    }
    getvideourl=(activityId,activityName,coverImgUrl)=>{
        let newListArray2 = [];
        let videoArray2=[];
        Axios.get(Api.getLiveInfo+'?activityId='+activityId).then((infores)=>{
            if(infores.data.data!=null){
                var playurl=infores.data.data.playUrl
                if(playurl!=''){//eslint-disable-line
                    newListArray2.push({
                        'title':activityName,
                        'src':  coverImgUrl,
                        'starttime':infores.data.data.liveStart?infores.data.data.liveStart:'',
                        'endtime':'',
                        'color':'#000',
                        'width':'90%',
                        'height':'70%',
                        'imagewidth':'97%'
                    });
                    newListArray2.forEach((item,index)=>{
                        if(index==0){//eslint-disable-line
                            newListArray2[index]['color']="red";
                        }
                    })
                    videoArray2.push(playurl)
                    this.setState({
                        appmenusimg: newListArray2,
                        vsrc: videoArray2,
                        ontitle:this.state.appmenusimg[0]['title'],
                        onimg:this.state.appmenusimg[0]['src'],
                        onstarttime:this.state.appmenusimg[0]['starttime']
                    })
                }
            }


        })
    }
    //播放视频
    plays=(videourl)=>{

        this.player = videojs("my-video1");
        // this.player.controls(true)
        this.player.src(videourl)

    }
    formatDate=(date) =>{
        var dates = new Date(date);
        var YY = dates.getFullYear() + '-';
        var MM = (dates.getMonth() + 1 < 10 ? '0' + (dates.getMonth() + 1) : dates.getMonth() + 1) + '-';
        var DD = (dates.getDate() < 10 ? '0' + (dates.getDate()) : dates.getDate());
        var hh = (dates.getHours() < 10 ? '0' + dates.getHours() : dates.getHours()) + ':';
        var mm = (dates.getMinutes() < 10 ? '0' + dates.getMinutes() : dates.getMinutes()) + ':';
        var ss = (dates.getSeconds() < 10 ? '0' + dates.getSeconds() : dates.getSeconds());
        return YY + MM + DD +" "+hh + mm + ss;
    }

    chenges=()=>{

        let thisvideo=this.state.videosrc;
        for(let i=0;i<this.state.vsrc.length;i++){
            if(thisvideo==this.state.vsrc[i]){//eslint-disable-line
                if(i!=(this.state.vsrc.length-1)){//eslint-disable-line
                    this.handleChange("videosrc",this.state.vsrc[i+1])
                }else{
                    this.handleChange("videosrc",this.state.vsrc[0])
                }

            }
        }
        let redtitel=this.state.redon;
        if(redtitel!=(this.state.appmenusimg.length-1)){//eslint-disable-line
            this.setState({
                appmenusimg: this.state.appmenusimg.map((item, _index) => _index == redtitel ? {...item, ['width']: '90%'} : item)//eslint-disable-line
            });
            this.setState({
                appmenusimg: this.state.appmenusimg.map((item, _index) => _index == redtitel ? {...item, ['imagewidth']: '70%'} : item)//eslint-disable-line
            });
            this.setState({
                appmenusimg: this.state.appmenusimg.map((item, _index) => _index == redtitel ? {...item, ['height']: '68%'} : item)//eslint-disable-line
            });
            this.setState({
                appmenusimg: this.state.appmenusimg.map((item, _index) => _index == (redtitel+1) ? {...item, ['width']: '95%'} : item)//eslint-disable-line
            });

            this.setState({
                appmenusimg: this.state.appmenusimg.map((item, _index) => _index == (redtitel+1) ? {...item, ['height']: '70%'} : item)//eslint-disable-line
            });
            this.setState({
                'ontitle':this.state.appmenusimg[redtitel+1]['title'],
                'onstarttime':this.state.appmenusimg[redtitel+1]['starttime'],
                redon:redtitel+1
            })
           // this.state.redon=redtitel+1;
        }else{
            this.setState({
                pmenusimg: this.state.appmenusimg.map((item, _index) => _index == redtitel ? {...item, ['width']: '90%'} : item)//eslint-disable-line
            });

            this.setState({
                pmenusimg: this.state.appmenusimg.map((item, _index) => _index == redtitel ? {...item, ['height']: '68%'} : item)//eslint-disable-line
            });
            this.setState({
                appmenusimg: this.state.appmenusimg.map((item, _index) => _index == (0) ? {...item, ['width']: '95%'} : item)//eslint-disable-line
            });

            this.setState({
                appmenusimg: this.state.appmenusimg.map((item, _index) => _index == (0) ? {...item, ['height']: '70%'} : item)//eslint-disable-line
            });
            this.setState({
                'ontitle':this.state.appmenusimg[0]['title'],
                'onstarttime':this.state.appmenusimg[0]['starttime'],
                redon:0
            })

        }

    }
    test(){
        console.log(123456)

        //this.player.pause();
        //this.player.playsInline(false)
    }

    //点击更换视频
    chengvideo=(v)=>{

        clearInterval(this.timers);
        let vc=v['key'];
        if(vc!=this.state.redon){//eslint-disable-line
           // console.log(this.state.vsrc[vc].indexOf(".mp4"))
            if(this.state.vsrc[vc].indexOf(".mp4")==-1){//eslint-disable-line
                this.timers=setInterval( ()=>{
                    let nowtime=(new Date()).valueOf();
                    let startimes=(new Date(this.state.appmenusimg[vc]['starttime'])).valueOf();
                    let endtimes=(new Date(this.state.appmenusimg[vc]['endtime'])).valueOf();

                    // if(nowtime>=startimes&&nowtime<=endtimes){
                    //      this.handleChange('onvideo','block');
                    //      this.handleChange('onimgshow','none');
                    //      this.plays(this.state.vsrc[vc])

                    // }else{

                    this.handleChange('videosrc','');
                    this.handleChange('onimg',this.state.appmenusimg[vc]['src']);
                    this.handleChange('onvideo','block');
                    this.handleChange('onimgshow','none');
                    clearInterval(this.timers);

                    // }
                }, 1000);
            }else{
                this.handleChange('onvideo','block');
                this.handleChange('onimgshow','none');
                this.plays(this.state.vsrc[vc])

            }

            let newsrc=this.state.vsrc[vc];
            let newimg=this.state.appmenusimg[v['key']];
            const updatedArray = this.state.appmenusimg;
            updatedArray[vc]['color']='red';
            updatedArray[vc]['width']="95%";
            updatedArray[vc]['height']="70%";
            updatedArray[vc]['imagewidth']="98%";
            updatedArray[this.state.redon]['width']='90%';
            updatedArray[this.state.redon]['height']='68%';
            updatedArray[this.state.redon]['imagewidth']='97%'
            updatedArray[this.state.redon]['color']="#000"
            //this.state.redon=vc;
            this.setState({
                appmenusimg:updatedArray,
                redon:vc,
                'ontitle':this.state.appmenusimg[vc]['title'],
                'onstarttime':this.state.appmenusimg[vc]['starttime']
            })
            // this.handleChange("videosrc",newsrc);

        }
    }
    //修改state
    handleChange=(name,val)=> {
        this.setState({
            [name]: val
        })

    }
    gundong=(v)=>{
        if(v==1){//eslint-disable-line
            let newsrc=this.state.vsrc[0];
            let newimg=this.state.appmenusimg[0];
            this.state.appmenusimg.splice(0,1);
            this.state.appmenusimg.push(newimg);
            this.state.vsrc.splice(0,1);
            this.state.vsrc.push(newsrc);
        }else{
            let newsrc=this.state.vsrc[this.state.appmenusimg.length-1];
            let newimg=this.state.appmenusimg[this.state.vsrc.length-1];
            this.state.appmenusimg.splice((this.state.appmenusimg.length-1),1);
            this.state.appmenusimg.unshift(newimg);
            this.state.vsrc.splice((this.state.vsrc.length-1),1);
            this.state.vsrc.unshift(newsrc);
        }
        for(let i=0;i<this.state.appmenusimg.length;i++){
            if(this.state.appmenusimg[i]['color']=='red'){//eslint-disable-line
                this.setState({
                    redon:i
                })
              //  this.state.redon=i;
            }
        }


        this.setState(this.state.appmenusimg);
        this.setState(this.state.vsrc);
    }
    // 这里是滚动事件
    onScrollCapture=()=> {
        if(this.state.typeid!=4){//eslint-disable-line
            return;
        }
        let scrollDiv = document.getElementById('androidmaps');
        let divheight = scrollDiv.clientHeight;
        //document.getElementsByClassName('androidmaps').style.height
        // console.log(divheight);

        let that = this
        // scrollDiv.addEventListener('scroll', function() {
        let SH = parseInt(scrollDiv.scrollTop + divheight);
        let H = parseInt(scrollDiv.scrollHeight);
        //  console.log(SH);
        //console.log(H)
        let time = null;
        clearTimeout(time);
        time = setTimeout(() => {
            if (SH >= H) {

                that.state.current += 1;

                //此时滚动条滚动到底部
                Axios.get(Api.getLives + '?current=' + that.state.current + '&resourceLevel=SABCD&size=10').then((infores) => {

                    //  console.log(infores.data.data.list);
                    let vlist = infores.data.data.list;
                    let newListArray = [];
                    let videoArray = [];

                    vlist.forEach((list, index) => {
                        //   console.log(new Date(vlist[index]['video_live_start_time']))
                        newListArray.push({
                            'title': vlist[index]['activity_name'],
                            'src': vlist[index]['cover_img_url'],
                            'starttime': '',
                            'endtime': '',
                            'color': '#000',
                            'width': '90%',
                            'height': '70%',
                            'imagewidth': '97%'
                        })
                        videoArray.push(vlist[index]['original_play_url'])

                        that.setState({
                            appmenusimg: [...that.state.appmenusimg, ...newListArray],
                            vsrc: [...that.state.vsrc, ...videoArray],
                        })
                        // that.state.current=  newcurrent
                        console.log(that.state.current)
                    })

                })
            }
        },1000)
    }

    render(){

        const { current } = this.state;

        return(
            !this.state.isgetData?(<div className="content">loading....</div>):(
            <div className="content">
                <div className="banner">
                    <img className="banner-img" alt={this.state.list1+this.state.bl} src={this.state.list1+this.state.bl} onClick={()=>this.test()}/>
                    <img className="banner-bg" alt={list2+this.state.bl} src={list2+this.state.bl}/>
                </div>
                <div className="list-middle" id="playvideos" >
                    <div className="middle-con" >
                        <img className="middle-video" alt={background+this.state.bl} src={background+this.state.bl} />
                        <div className="zhongs" >
                            <div className="middlecart" >
                                <div className="phones" id="phone" >
                                    <div className="dvideos">
                                        <div style={{width:'100%',height:'608px',display:this.state.onvideo}}>
                                            <video
                                                id={ "my-video1"}
                                                className="video-js vjs-default-skin"
                                                autoPlay="autoplay"
                                                playsInline="true"
                                                src={this.state.vsrc[0]}
                                                controls
                                            ></video>
                                        </div>
                                        <div className='contenttitle' >
                                            <div className="title-name">{this.state.ontitle}</div>
                                            {this.state.onstarttime? <div className="title-time">直播时间:{this.state.onstarttime}</div> :''}
                                        </div>
                                    </div>
                                </div>
                                <div className="rightcontents">
                                    <div className="qrcodes">
                                        <div className="right-title">精选</div>
                                        <div className="androidmaps" id="androidmaps" onScrollCapture={ this.onScrollCapture }  >
                                            {this.state.appmenusimg.map((item,key) => (
                                                <div className="appmenu1s"  onClick={()=>this.chengvideo({key})} key={key}>
                                                    <div className="leftnnd">
                                                        <img src={item.src+this.state.bls} alt={item.src+this.state.bls} className="leftplayer"/>
                                                    </div>
                                                    <div className="rightnnd">
                                                        <div className="lbtitle" style={{color:item.color}}>{item.title}</div>
                                                        <div className="lbtime" style={{color:item.color}}>{item.starttime}</div>
                                                    </div>
                                                </div>

                                            ))}

                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                    <div className="dibu">
                        <img className="" src={list6+this.state.bl} alt={list6+this.state.bl}/>
                    </div>

                </div>

            </div>
            )
        )
    }
}

export default List;
