import React, {Component} from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import '../../../assets/whypc/css/swiper.css';
import '../../../assets/whypc/css/pagination.min.css';
import mySwiper,{ Autoplay, Pagination } from "swiper";


import ReactDOM from 'react-dom';
import Api from "../../model/Api";
import Axios from "axios";
import {HashRouter as Router, Route, Link, Redirect} from 'react-router-dom';
import '../../../assets/whypc/css/base.css';
import '../../../assets/whypc/css/zhuanti.css';
import {Carousel, Layout} from 'antd';
import Logo from "../../../assets/whypc/image/logo.png";
import banner from "../../../assets/whypc/image/aboutbanner.png";
import about from "../../../assets/whypc/image/about.png";
import lxwm from "../../../assets/whypc/image/lxwm.png";
import bootom from "../../../assets/whypc/image/bottom.png";
import beian from "../../../assets/whypc/image/beian.png";

import banner1 from "../../../assets/whypc/image/zhuanti1.png";
import fenleiImg2 from "../../../assets/whypc/image/zhuanti2.png";
import fenleiImg3 from "../../../assets/whypc/image/zhuanti3.png";
import fenleiImg4 from "../../../assets/whypc/image/zhuanti4.png";
import fenleiImg5 from "../../../assets/whypc/image/zhuanti5.png";
import fenleiImg6 from "../../../assets/whypc/image/zhuanti6.png";
import fenleiImg7 from "../../../assets/whypc/image/zhuanti7.png";
import fenleiImg8 from "../../../assets/whypc/image/zhuanti8.png";
import fenleiImg9 from "../../../assets/whypc/image/zhuanti9.png";
import fenleiImg14 from "../../../assets/whypc/image/zhuanti14.png";
import fenleiImg15 from "../../../assets/whypc/image/zhuanti15.png";
import zhuantiFoot from "../../../assets/whypc/image/list6.png";

import data from "./LunBoControlConfig.js";
import main from  '../../../assets/whypc/css/main.css';
// 轮播组件
import LunBoControl from './LunBoControl';




const { Header, Content,Footer } = Layout;
mySwiper.use([Autoplay, Pagination])
class Zhuanti extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
        	seletKey:2,
			bl:'?x-oss-process=style/wbpdt',
			bannerlist:[],
			navigation:[],
			imagedh:[],
			imgArray: [
				'',
				'',
				'',
				'',
				'',
			],
			linkArray: [
				"#",
				"#",
				"#",
				"#",
				"#",
			],
			title:[
				"",
				"",
				"",
				"",
				""
			],
			lunboObject: {
				"width":1323,//幻灯片的宽度
				"height":388,//幻灯片的高度
				"imgWidth":690,//幻灯片第一帧的宽度
				"interval": 2000,//幻灯片滚动的间隔时间
				"scale":0.76, //记录显示比例关系
				"number":5,
				"autoPlay":true,
				"vertical":"center",  // center或者bottom,居中对齐或底部对齐
				"tweenString":"QuadEaseIn" // 运动方式，缓冲曲线
			},
			getData:false
        }
    }
	componentDidMount(){
		window.scrollTo(0,0)
		this.getbanner(1);
		this.getnavigation(2);
		this.getimgdh(3);
		this.getzhibo(5);
		this.setState({
			getData:true,
		})
	}
	getnavigation=(type)=>{
		var params = new URLSearchParams();
		params.append('type',type);
		params.append('listOrder',1);
		params.append("current",1)
		params.append('size',15);
		Axios.post(Api.getzhuantibanner, params)
			.then((res) => {
				let newArray = [];

				let blist=res.data.data.list
				blist.forEach((list,index) => {
					newArray.push({
						'id':blist[index]['id'],
						'coverImageUrl':blist[index]['coverImageUrl'],
						'serialNumber':blist[index]['serialNumber'],
						'title':blist[index]['title']
					})
				})
				this.setState({
					navigation:newArray
				})
			})
	}
	getbanner=(type)=>{
		var params = new URLSearchParams();
		params.append('type',type);
		params.append('listOrder',3);
		params.append("current",1)
		params.append('size',15);
		Axios.post(Api.getzhuantibanner, params,)
			.then((res) => {
				let newListArray = [];
				let bannerlist=res.data.data.list
				bannerlist.forEach((list,index) => {
					newListArray.push({
						'id':bannerlist[index]['id'],
						'coverImageUrl':bannerlist[index]['coverImageUrl'],
						'serialNumber':bannerlist[index]['serialNumber'],
					})
				})
				this.setState({
					bannerlist:newListArray
				})

			})
	}
	getimgdh=(type)=>{
		var params = new URLSearchParams();
		params.append('type',type);
		params.append('listOrder',1);
		params.append("current",1)
		params.append('size',15);
		Axios.post(Api.getzhuantibanner, params,)
			.then((res) => {
				let imagesArray = [];
				let bslist=res.data.data.list
				bslist.forEach((list,index) => {
					imagesArray.push({
						'id':bslist[index]['id'],
						'coverImageUrl':bslist[index]['coverImageUrl'],
						'serialNumber':bslist[index]['serialNumber'],
						'title':bslist[index]['title']
					})
				})
				this.setState({
					imagedh:imagesArray
				})

			})
	}
	getzhibo=(size)=>{
		Axios.get(Api.getLives+'?current=1&size='+size).then((infores)=>{
			let vlist=infores.data.data.list;
			let imgArrays=[];
			let titleArray=[];
			let linkArrays=[];
			vlist.forEach((list,index)=>{
				imgArrays.push(vlist[index]['cover_img_url']);
				titleArray.push(vlist[index]['activity_name']);
				linkArrays.push('/#/list?typeid=4&serialNumber=&id=10');
			})
			this.setState({
				imgArray:imgArrays,
				title:titleArray,
				linkArray:linkArrays
			})
		})
	}
	gotolist=(serialNumber)=>{
		return <Redirect to={{pathname: `/list`} }/>;
	}
    render(){
        const contentStyle = {
            height: '160px',
            color: '#fff',
            lineHeight: '160px',
            textAlign: 'center',
            background: '#364d79',
        };
        return(
			!this.state.getData?(<div className="content">loading....</div>):(
            <div className="zhuantidiv">
			 <Swiper
					loop={this.state.bannerlist && this.state.bannerlist.length > 1}
			        slidesPerView={1}
			        modules={[Pagination,Autoplay]}
					autoplay={{
					          delay: 2500,
					          disableOnInteraction: false,
					        }}
			        pagination={{
			          clickable: true,
			        }}
			       // modules={[Pagination]}
			        className="mySwiper"
			      >
				 {this.state.bannerlist.map((item,key)=>(
			        <SwiperSlide key={key} onClick={()=>this.props.history.replace("/list?typeid=1&serialNumber="+item.serialNumber+"&id="+item.id)}><img src={item.coverImageUrl+this.state.bl} alt={item.coverImageUrl}/></SwiperSlide>
					 ))}

			      </Swiper>

				<div className="fenlei1">
					<ul className="fenlei1-list">
						{this.state.navigation.map((item,key)=>(
					<Link to={"/list?typeid=2&serialNumber="+item.serialNumber+"&id="+item.id}>
						<li className="fenlei1-item" key={key}>
							<img className="fenlei1-img" src={item.coverImageUrl+this.state.bl} alt={item.coverImageUrl}/>
							<div className="fenlei1-name">
								{item.title}
							</div>
						</li>
						</Link>
						))}

					</ul>
				</div>
                <div className="fenlei2">
					<ul className="fenlei2-list">
						{this.state.imagedh.map((item,key)=>(
							<Link to={"/list?typeid=3&serialNumber="+item.serialNumber+"&id="+item.id}>
								<li className="fenlei2-item"  key={key}>
									<img className="fenlei2-img" src={item.coverImageUrl+this.state.bl} alt={item.coverImageUrl}/>
								</li></Link>
						))}
					</ul>
				</div>
				<div className="jingcai-zhibo">
					<div className="jingcai-title">精彩直播</div>
					<Link to={"/list?typeid=4&serialNumber=&id=10#list-middle"}><div className="jingcai-more" >更多>></div></Link>
				</div>
				<div className={main['ad-img']}>
				    <LunBoControl lunboObject={this.state.lunboObject} imgArray={this.state.imgArray} title={this.state.title} linkArray={this.state.linkArray} />
				</div>
                <div className="zhuanti-dibu">
                	<img className="" src={zhuantiFoot+this.state.bl} alt={zhuantiFoot}/>
                </div>


            </div>
			)
        )
    }

}
export default Zhuanti;
