const Api = {
    //添加/修改荐书人
    addCommendBooksPerson: "/switch/referrer/save",
    //搜索荐书人
    searchCommendBooksPerson: "/switch/referrer/search",
   //下载荐书人
    downCommendBooksPerson: "/switch/referrer/download",
    //根据ID查询荐书人
    searchCommendBooksPersonById: "/switch/referrer/searchById",
    //删除荐书人
    deleteCommendBookPerson: "/switch/referrer/delete",
     //获取职业列表
     getProfessions: "/switch/referrer/getProfessions",
      //获取出版社列表
      getBanks: "/switch/referrer/getBanks",
    //获取全部書籍列表
    getBooks: "/switch/referrer/getBooks",
    //上传图片
     upLoadImage: "/switch/upload/image",
      //登录
     login: "/switch/login/do",
       //退出登录
     loginOut: "/switch/login/out",
      //鉴别图书
     checkBook: "/lo/checkBook",
      //鉴别图书
      checkBookByExcel: "/lo/checkBookByExcel2",
    //获取云专题
    getzhuantibanner:"https://culture.hnbxwhy.com/zmdss/cloudSubjectCover/list",
    getCloudBanner:  'https://culture.hnbxwhy.com/zmdss/cloudSubjectCover/getById',
    getFestival:'https://culture.hnbxwhy.com/resource/mobile/cloud/festival',
    getLives:"https://culture.hnbxwhy.com/zmdss/whyOtherDb/activity_getSowingActList",
    getLiveInfo:'https://culture.hnbxwhy.com/live/mobile/getLiveInfo'

}
export default Api;
