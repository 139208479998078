import React, { useMemo, useState, useEffect } from 'react';
import "video.js/dist/video-js.css";
import "videojs-flash";
import videojs from "video.js";
import ReactPlayer from 'react-player';


//import '../../../assets/whypc/css/reading.css';
import "../../../../node_modules/video-react/dist/video-react.css";
//import {Redirect}from 'react-router-dom';
//import Api from '../../model/Api';
//import Axios from 'axios'
import { Layout, Menu, Breadcrumb,Carousel,input } from 'antd';
//import ptitle from "../../../assets/images/why191108_login_title.png";
import Logo from "../../../assets/whypc/image/logo.png";
import  backmp4 from "../../../assets/whypc/image/backmp4.mp4";
import  video from "../../../assets/whypc/image/news1.mp4";
import  video2 from "../../../assets/whypc/image/news2.mp4";
import  video3 from "../../../assets/whypc/image/news3.mp4"
import  video4 from "../../../assets/whypc/image/news4.mp4"
import  video5 from "../../../assets/whypc/image/news5.mp4"
import  video6 from "../../../assets/whypc/image/news6.mp4"
import  video7 from "../../../assets/whypc/image/news7.mp4"
import  video8 from "../../../assets/whypc/image/news8.mp4"
import  video9 from "../../../assets/whypc/image/news9.mp4";
import  homeimg from "../../../assets/whypc/image/home.png";
import  zhibo from "../../../assets/whypc/image/zhibo.png";
import  zmdss from "../../../assets/whypc/image/zmdss.png";
import  huodongimg from "../../../assets/whypc/image/huodong.png";
import  my from "../../../assets/whypc/image/my.png";
import  homeimgafter from "../../../assets/whypc/image/homeafter.png";
import  zhiboafter from "../../../assets/whypc/image/zhiboafter.png";
import  zmdssafter from "../../../assets/whypc/image/zmdssafter.png";
import  huodongimgafter from "../../../assets/whypc/image/huodongafter.png";
import  myafter from "../../../assets/whypc/image/myafter.png";
import logoimg from "../../../assets/whypc/image/logoimg.png";
import android from "../../../assets/whypc/image/android.png";
import iosimg from "../../../assets/whypc/image/ios.png";
import xiaochengxu from "../../../assets/whypc/image/xiaochengxu.png";
import beian from "../../../assets/whypc/image/beian.png";
import videotp from "../../../assets/whypc/image/videotp.jpg";
import xiaochegnxuma from "../../../assets/whypc/image/xiaochengxuma.png";
import quanminyuedu from "../../../assets/whypc/image/quanminyuedu.png";
import dushu from "../../../assets/whypc/image/dushu.png";
import bofang from "../../../assets/whypc/image/bofang.png";
import videopg from "../../../assets/whypc/image/playering3.png";
import videopg2 from "../../../assets/whypc/image/playering2.png";
import videopg3 from "../../../assets/whypc/image/playering3.png";
import videopg4 from "../../../assets/whypc/image/playering4.png";
import videopg5 from "../../../assets/whypc/image/playering5.png";
import videopg6 from "../../../assets/whypc/image/playerpg.png";
import videopg7 from "../../../assets/whypc/image/playerpg.png";
import videopg8 from "../../../assets/whypc/image/playerpg.png";
import videopg9 from "../../../assets/whypc/image/playerpg.png";
import background from "../../../assets/whypc/image/background.png";
import leftimg from "../../../assets/whypc/image/left.png";
import rightimg from "../../../assets/whypc/image/right.png";
import { Player, ControlBar, ReplayControl,
    ForwardControl, CurrentTimeDisplay,
    TimeDivider, PlaybackRateMenuButton, VolumeMenuButton } from 'video-react';

const { Header, Content,Footer } = Layout;


class Players extends React.Component{

    constructor(props) {
        super(props);

        this.state = {
            windowwidth:'1990px',
            windowheight:'800px',
            setShowNextButton:0,
            vcount:5,
            abc:'0',
            //videosrc:video,
            videosrc:  'rtmp://qcloud-play.lezhibo.com/live/10349_2c57d5ed45a146a2861faf8b71ac5c6f',
           // videosrc: 'rtmp://live2.hnbxwhy.com/bxwhy/why_live17?auth_key=1663129800-0-0-c5b0c21c1386963e6d49e2fa6a17aa33',
            li:0,
            vsrc:[
                'rtmp://qcloud-play.lezhibo.com/live/10349_2c57d5ed45a146a2861faf8b71ac5c6f',
                'http://video.hnbxwhy.com/live/record/bxwhy/why_live20/2022-09-18-14-55-36_2022-09-18-16-17-56.mp4',
                'http://video.hnbxwhy.com/live/record/bxwhy/why_live28/2022-09-17-16-18-31_2022-09-17-18-27-16.mp4',
                /* 'http://video.hnbxwhy.com/7592822/%E7%9B%B4%E6%92%AD%E8%A7%86%E9%A2%91/%E6%B8%85%E6%98%8E%E4%B8%8A%E6%B2%B3%E5%9B%BE%20%E5%89%AA.mp4',

              /* 'rtmp://media3.scctv.net/live/scctv_800',
                 'rtmp://mobliestream.c3tv.com:554/live/goodtv.sdp',
                 'rtmp://media3.scctv.net/live/scctv_800',
                 'rtmp://mobliestream.c3tv.com:554/live/goodtv.sdp',*/
            ],
            appmenusimg:[
                {
                    'title':'主题讲座：爱读书的孩子是怎样养成的',
                    'src': videopg3,
                    'starttime':'2022-09-22 15:00:00',
                    'endtime':'2022-09-22 16:30:00',
                    'color':'#ffff',
                    'width':'90%',
                    'height':'70%',
                    'imagewidth':'97%'
                },
                {
                    'title':'【书香河南·对谈名家】站在十字路口——馒头大师《历史的温度》系列读者分享会',
                    'src': videopg5,
                    'starttime':'2022-09-18 15:00:00',
                    'endtime':'2022-09-18 17:14:52',
                    'color':'#ffff',
                    'width':'90%',
                    'height':'68%',
                    'imagewidth':'97%'
                },

                {
                    'title':'读经典书籍 建书香人家 修家国情怀——周慧玲老师见面会',
                    'src': videopg2,
                    'starttime':'2022-09-17 16:00:00',
                    'endtime':'2022-09-17 18:40:00',
                    'color':'#ffff',
                    'width':'90%',
                    'height':'68%',
                    'imagewidth':'97%'
                },


            ],
            redon:0,
            ontitle:'【书香河南·对谈名家】站在十字路口——馒头大师《历史的温度》系列读者分享会',
            onimg:videopg,
            onimgshow:'block',
            onvideo:'none'
        };
        this.player=null;
    }


    componentDidMount(){

        if(localStorage.getItem("newplayer")!=1){//eslint-disable-line

            localStorage.setItem("newplayer",1);
            window.location.reload();
        }
        // if(document.body.clientWidth=="1274"){
        //     for(let i=0;i<this.state.appmenusimg.length;i++){
        //         this.setState({
        //
        //             appmenusimg: this.state.appmenusimg.map((item, _index) => _index == i ? {...item, ['height']: '106px'} : item)
        //
        //
        //         });
        //     }
        // }
        if(this.state.vsrc[0].indexOf(".mp4")==-1) {//eslint-disable-line
        this.timer=setInterval( ()=>{

            let nowtime=(new Date()).valueOf();
            let startimes=(new Date(this.state.appmenusimg[0]['starttime'])).valueOf();
            let endtimes=(new Date(this.state.appmenusimg[0]['endtime'])).valueOf();

                if (nowtime >= startimes && nowtime <= endtimes) {
                    this.handleChange('onvideo', 'block');
                    this.plays(this.state.vsrc[0])
                    this.handleChange('onimgshow', 'none');
                    this.handleChange('videosrc', this.state.vsrc[0]);
                    clearInterval(this.timer);
                    // debugger;
                } else {

                    this.handleChange('videosrc', '');
                    this.handleChange('onimg', this.state.appmenusimg[0]['src']);
                    this.handleChange('onvideo', 'none');
                    this.handleChange('onimgshow', 'block');
                }

        }, 2000);
        }else{
            this.handleChange('onvideo','block');
            this.handleChange('onimgshow','none');
            this.plays(this.state.vsrc[0])
        }
        localStorage.setItem("seletKey",2);
        console.log(localStorage.getItem("newplayer"))


        this.handleChange('windowwidth',document.body.clientWidth*0.92);
        this.handleChange('windowheight',document.body.clientHeight*0.96);


    }
    componentWillUnmount() {

        clearInterval(this.timer);
        clearInterval(this.timers);
    }
    //判断直播是否开始
    // isplay=(n)=>{
    //
    //     let nowtime=(new Date()).valueOf();
    //     let startimes=(new Date(this.state.appmenusimg[n]['starttime'])).valueOf();
    //
    //     let endtimes=(new Date(this.state.appmenusimg[n]['endtime'])).valueOf();
    //     if(nowtime>=startimes&&nowtime<=endtimes){
    //         this.handleChange('onvideo','block');
    //         this.plays(this.state.vsrc[n])
    //         this.handleChange('onimgshow','none');
    //
    //     }else{
    //
    //         this.handleChange('onimg',this.state.appmenusimg[n]['src']);
    //         this.handleChange('onvideo','none');
    //         this.handleChange('onimgshow','block');
    //         clearInterval();
    //     }
    // }
    //播放视频
    plays=(videourl)=>{
        console.log(videourl)
        this.player = videojs("my-video1");
       // this.player.controls(true)
        this.player.src(videourl)

    }

    chenges=()=>{

        let thisvideo=this.state.videosrc;
        for(let i=0;i<this.state.vsrc.length;i++){
            if(thisvideo==this.state.vsrc[i]){//eslint-disable-line
                if(i!=(this.state.vsrc.length-1)){//eslint-disable-line
                    this.handleChange("videosrc",this.state.vsrc[i+1])
                }else{
                    this.handleChange("videosrc",this.state.vsrc[0])
                }

            }
        }
        let redtitel=this.state.redon;
        if(redtitel!=(this.state.appmenusimg.length-1)){//eslint-disable-line
            this.setState({
                appmenusimg: this.state.appmenusimg.map((item, _index) => _index == redtitel ? {...item, ['width']: '90%'} : item)//eslint-disable-line
            });
            this.setState({
                appmenusimg: this.state.appmenusimg.map((item, _index) => _index == redtitel ? {...item, ['imagewidth']: '70%'} : item)//eslint-disable-line
            });
            // if(document.body.clientWidth<="1280"){
            //     this.setState({
            //         appmenusimg: this.state.appmenusimg.map((item, _index) => _index == redtitel ? {...item, ['height']: '105px'} : item)
            //     });
            // }else{
            //     this.setState({
            //         appmenusimg: this.state.appmenusimg.map((item, _index) => _index == redtitel ? {...item, ['height']: '160px'} : item)
            //     });
            // }
            this.setState({
                appmenusimg: this.state.appmenusimg.map((item, _index) => _index == redtitel ? {...item, ['height']: '68%'} : item)//eslint-disable-line
            });
            this.setState({
                appmenusimg: this.state.appmenusimg.map((item, _index) => _index == (redtitel+1) ? {...item, ['width']: '95%'} : item)//eslint-disable-line
            });
            // if(document.body.clientWidth<="1280"){
            //     this.setState({
            //         appmenusimg: this.state.appmenusimg.map((item, _index) => _index == (redtitel+1) ? {...item, ['height']: '107px'} : item)
            //     });
            // }else{
            //     this.setState({
            //         appmenusimg: this.state.appmenusimg.map((item, _index) => _index == (redtitel+1) ? {...item, ['height']: '170px'} : item)
            //     });
            // }
            this.setState({
                appmenusimg: this.state.appmenusimg.map((item, _index) => _index == (redtitel+1) ? {...item, ['height']: '70%'} : item)//eslint-disable-line
            });
            this.setState({
                'ontitle':this.state.appmenusimg[redtitel+1]['title'],
                redon:redtitel+1
            })
           // this.state.redon=redtitel+1;

        }else{
            this.setState({
                pmenusimg: this.state.appmenusimg.map((item, _index) => _index == redtitel ? {...item, ['width']: '90%'} : item)//eslint-disable-line
            });
            // if(document.body.clientWidth<="1280"){
            //     this.setState({
            //         pmenusimg: this.state.appmenusimg.map((item, _index) => _index == redtitel ? {...item, ['height']: '105px'} : item)
            //     });
            // }else{
            //     this.setState({
            //         pmenusimg: this.state.appmenusimg.map((item, _index) => _index == redtitel ? {...item, ['height']: '160px'} : item)
            //     });
            // }
            this.setState({
                pmenusimg: this.state.appmenusimg.map((item, _index) => _index == redtitel ? {...item, ['height']: '68%'} : item)//eslint-disable-line
            });
            this.setState({
                appmenusimg: this.state.appmenusimg.map((item, _index) => _index == (0) ? {...item, ['width']: '95%'} : item)//eslint-disable-line
            });
            // if(document.body.clientWidth<="1280"){
            //     this.setState({
            //         appmenusimg: this.state.appmenusimg.map((item, _index) => _index == (0) ? {...item, ['height']: '107px'} : item)
            //     });
            // }else{
            //     this.setState({
            //         appmenusimg: this.state.appmenusimg.map((item, _index) => _index == (0) ? {...item, ['height']: '170px'} : item)
            //     });
            // }
            this.setState({
                appmenusimg: this.state.appmenusimg.map((item, _index) => _index == (0) ? {...item, ['height']: '70%'} : item)//eslint-disable-line
            });
            this.setState({
                'ontitle':this.state.appmenusimg[0]['title'],
                redon:0
            })
            //this.state.redon=0;
        }

    }


    //点击更换视频
    chengvideo=(v)=>{

        clearInterval(this.timer);
        let vc=v['key'];
        if(vc!=this.state.redon){//eslint-disable-line
                if(this.state.vsrc[vc].indexOf(".mp4")==-1){//eslint-disable-line
                    this.timer=setInterval( ()=>{
                        let nowtime=(new Date()).valueOf();
                        let startimes=(new Date(this.state.appmenusimg[vc]['starttime'])).valueOf();
                        let endtimes=(new Date(this.state.appmenusimg[vc]['endtime'])).valueOf();
                        if(nowtime>=startimes&&nowtime<=endtimes){
                            this.handleChange('onvideo','block');
                            this.handleChange('onimgshow','none');
                            this.plays(this.state.vsrc[vc])

                        }else{

                            this.handleChange('videosrc','');
                            this.handleChange('onimg',this.state.appmenusimg[vc]['src']);
                            this.handleChange('onvideo','none');
                            this.handleChange('onimgshow','block');
                            clearInterval(this.timers);
                        }
                    }, 2000);
                }else{

                    this.handleChange('onvideo','block');
                    this.handleChange('onimgshow','none');
                    this.plays(this.state.vsrc[vc])
                }
            let newsrc=this.state.vsrc[vc];
            let newimg=this.state.appmenusimg[v['key']];
            const updatedArray = this.state.appmenusimg;
            updatedArray[vc]['color']='red';
            updatedArray[vc]['width']="95%";
            updatedArray[vc]['height']="70%";
            updatedArray[vc]['imagewidth']="98%";
            updatedArray[this.state.redon]['width']='90%';
            updatedArray[this.state.redon]['height']='68%';
            updatedArray[this.state.redon]['imagewidth']='97%'
            updatedArray[this.state.redon]['color']="#000"
            // this.state.appmenusimg[vc]['width']="95%";
            // this.state.appmenusimg[vc]['height']="70%";
            // this.state.appmenusimg[vc]['imagewidth']="98%";
            // this.state.appmenusimg[this.state.redon]['width']='90%';
            // this.state.appmenusimg[this.state.redon]['height']='68%';
            // this.state.appmenusimg[this.state.redon]['imagewidth']='97%'
            // this.state.redon=vc;

           // this.plays(newsrc)
            //this.handleChange("videosrc",newsrc);
            this.setState({
                appmenusimg:updatedArray,
                redon:vc,
                'ontitle':this.state.appmenusimg[vc]['title']
            })

            // this.handleChange("videosrc",newsrc);

        }
    }
    //修改state
    handleChange=(name,val)=> {
        this.setState({
            [name]: val
        })

    }
    gundong=(v)=>{
        if(v==1){//eslint-disable-line
            let newsrc=this.state.vsrc[0];
            let newimg=this.state.appmenusimg[0];
            this.state.appmenusimg.splice(0,1);
            this.state.appmenusimg.push(newimg);
            this.state.vsrc.splice(0,1);
            this.state.vsrc.push(newsrc);
        }else{
            let newsrc=this.state.vsrc[this.state.appmenusimg.length-1];
            let newimg=this.state.appmenusimg[this.state.vsrc.length-1];
            this.state.appmenusimg.splice((this.state.appmenusimg.length-1),1);
            this.state.appmenusimg.unshift(newimg);
            this.state.vsrc.splice((this.state.vsrc.length-1),1);
            this.state.vsrc.unshift(newsrc);
        }
        for(let i=0;i<this.state.appmenusimg.length;i++){
            if(this.state.appmenusimg[i]['color']=='red'){//eslint-disable-line
                //this.state.redon=i;
                this.setState({
                    redon:i
                })
            }
        }


        this.setState(this.state.appmenusimg);
        this.setState(this.state.vsrc);
    }

    render(){

        const { current } = this.state;

        return(
            <div className="content">
                <div className="middle">
                    <img className="video" src={background} alt={background}/>

                    <div className="zhongs" >
                        <div className="middlecart" style={{width:this.state.windowwidth}}>
                            <div className="phones" id="phone" >

                                {/*<div className="phoneimg"></div>*/}
                                <div className="dvideos" style={{width:'100%',height:this.state.windowheight}}>
                                        <img src={this.state.onimg} alt={this.state.onimg} className="onimg" style={{display:this.state.onimgshow}}/>
                                        <div style={{width:'100%',height:'88%',display:this.state.onvideo}}>
                                        <video
                                            id={ "my-video1"}
                                            className="video-js vjs-default-skin"
                                            autoPlay="autoplay"
                                            playsInline="true"
                                            controls
                                        ></video>
                                        </div>
                                    <div className='contenttitle' >{this.state.ontitle}</div>
                                </div>
                            </div>

                            <div className="rightcontents">

                                <div className="qrcodes">
                                    <div className="androidmaps" style={{height:this.state.windowheight,width:this.state.windowwidth*0.2}} >
                                        {this.state.appmenusimg.map((item,key) => (
                                            <div className="appmenu1s"  onClick={()=>this.chengvideo({key})} key={key}>
                                                <div className="leftnnd" style={{width:item.width,height:item.height,background:"#fff"}}>
                                                <img src={bofang} className="bofangng" alt={bofang}/>
                                                <img src={item.src} className="leftplayer" alt={item.src} style={{width:item.imagewidth,height:'100%'}}/>
                                                </div>
                                                <div className="lbtitle" style={{color:item.color,word_break:'normal',width:item.width,height:'30%'}} >{item.title}</div>
                                            </div>

                                        ))}

                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>

                </div>



            </div>

        )
    }
}

export default Players;
